<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";
import ShipperDetailModal from "./modals/shipperDetail"
import DeletionModal from "../../../../components/widgets/deletion-modal.vue"

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "shippers",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    ShipperDetailModal,
    DeletionModal
  },
  data() {
    return {
      title: "Shippers",
      isLoading: false,
      items: [
        {
          text: "shippers",
          href: "/shippers",
        },
        {
          text: "",
          active: true,
        },
      ],
      shippers: [],
      shipperSubmit: false,
      shipperForm: {
        name: null,
        code: null,
        phoneNumber: null
      }
    };
  },
  created() {
    //Load shippers
    this.isLoading = true;
    CalendarApiService.getShippers().then((response) => {
      this.shippers = response.data.data;
      this.isLoading = false;
    })
  },
  mounted() { },
  methods: {
    addJobType() {

    },
    showDeletionModal(shipper) {
      this.$refs.deletionModal.show(shipper).then(() => {
        this.isLoading = true;
        CalendarApiService.deleteShipper(shipper.idPublic).then(() => {
          var index = this.shippers.indexOf(shipper);
          this.shippers.splice(index, 1);
        }).finally(() => {
          this.isLoading = false;
        })
      });
    },
    submitShipper() {
      this.shipperSubmit = true;
      this.$v.shipperForm.$touch()

      if (!this.$v.shipperForm.$invalid) {
        this.isLoading = true;
        CalendarApiService.createShipper(this.shipperForm).then((response) => {
          let idPublic = response.data.data.idPublic;
          let model = {
            idPublic: idPublic,
            name: this.shipperForm.name,
            code: this.shipperForm.code,
            phoneNumber: this.shipperForm.phoneNumber
          };
          this.shippers.push(model);
          this.isLoading = false;

          //reset form
          this.shipperForm.name = null;
          this.shipperForm.code = null;
          this.shipperForm.phoneNumber = null;
          this.shipperSubmit = false;
        })
      }
    },
    editShipper(shipper) {
      this.$bus.$emit('open-edit-shipper-dialog', shipper);
    }
  },
  validations: {
    shipperForm: {
      name: { required },
      code: { required }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('general.shippers')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p class="card-title-desc mb-4">
                  {{ $t('general.shippersList') }}
                </p>
                <form @submit.prevent="submitShipper">
                  <div class="row" style="align-items:flex-end; max-width:900px;">
                    <div class="col-12 col-md-3">
                      <div class="form-group" style="position:relative;">
                        <label>{{ $t('general.name') }}</label>
                        <b-form-input :class="{ 'is-invalid': shipperSubmit && $v.shipperForm.name.$error }"
                          style="max-width:300px;" v-model="shipperForm.name" :placeholder="$t('general.name')">
                        </b-form-input>
                        <div v-if="shipperSubmit && $v.shipperForm.name.$error" class="invalid-tooltip">
                          <span v-if="!$v.shipperForm.name.required">{{ $t('general.requiredValue') }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-3">
                      <div class="form-group" style="position:relative;">
                        <label>Code</label>
                        <b-form-input :class="{ 'is-invalid': shipperSubmit && $v.shipperForm.code.$error }"
                          style="max-width:300px;" v-model="shipperForm.code" placeholder="Code">
                        </b-form-input>
                        <div v-if="shipperSubmit && $v.shipperForm.code.$error" class="invalid-tooltip">
                          <span v-if="!$v.shipperForm.code.required">{{ $t('general.requiredValue') }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-3">
                      <div class="form-group" style="position:relative;">
                        <label>{{ $t('general.phoneNumber') }}</label>
                        <b-form-input style="max-width:300px;" v-model="shipperForm.phoneNumber" v-mask="'### ###-####'"
                          :placeholder="$t('general.phoneNumber')">
                        </b-form-input>
                      </div>
                    </div>

                    <div class="col-12 col-md-2">
                      <b-form-group>
                        <b-button type="submit" variant="primary">{{ $t('general.add') }}</b-button>
                      </b-form-group>
                    </div>
                  </div>

                </form>
              </div>
            </div>

            <div ref="progressTable" class="table-responsive">
              <loading :active.sync="isLoading" :can-cancel="false" color='#f1b44c' :width=70 :height=70 loader='dots'
                :is-full-page="false">
              </loading>
              <table class="table mb-0 table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t('general.name') }}</th>
                    <th style="text-align:center; width:200px;">Code</th>
                    <th style="text-align:center; width:200px;">{{ $t('general.phoneNumber') }}</th>
                    <th style="width:100px; text-align:center;">{{ $t('general.action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(shipper, index) in shippers" :key="index">
                    <td>{{ shipper.name }}</td>
                    <td style="text-align:center;">{{ shipper.code }}</td>
                    <td style="text-align:center;">{{ shipper.phoneNumber }}</td>
                    <td style="text-align:center;">
                      <b-dropdown dropleft size="lg" class="card-drop" variant="link"
                        toggle-class="text-decoration-none p-0" no-caret>
                        <template #button-content>
                          <i class="fas fa-ellipsis-h" />
                        </template>

                        <b-dropdown-item @click="editShipper(shipper)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> {{ $t('general.edit') }}
                        </b-dropdown-item>

                        <b-dropdown-item @click="showDeletionModal(shipper)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> {{ $t('general.delete') }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletionModal ref="deletionModal" :content="this.$t('general.deleteShipperContent')" :title="this.$t('general.deleteShipperTitle')" />
    <ShipperDetailModal />
  </Layout>
</template>
