
<script>
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  data() {
    return {
      showModal: false,
      title: "Edit shipper",
      shipperForm: {
        name: null,
        code: null,
        phoneNumber: null,
      },
      shipperFormSubmit: false,
      shipperRef: null,
      isLoading: false,
    };
  },
  created() {
    this.$bus.$on("open-edit-shipper-dialog", this.openEditShipperDialog);
  },
  beforeDestroy() {
    this.$bus.$on("open-edit-shipper-dialog", this.openEditShipperDialog);
  },
  computed: {},
  methods: {
    openEditShipperDialog: function (shipper) {
      this.shipperRef = shipper;
      this.showModal = true;
      this.shipperForm.idPublic = shipper.idPublic;
      this.shipperForm.name = shipper.name;
      this.shipperForm.code = shipper.code;
      this.shipperForm.phoneNumber = shipper.phoneNumber;
    },
    closeModal() {
      this.showModal = false;
      this.shipperFormSubmit = false;
      this.shipperForm = {
        name: null,
        code: null,
        phoneNumber: null,
      };
    },
    submit() {
      this.shipperFormSubmit = true;
      this.$v.shipperForm.$touch();

      if (!this.$v.shipperForm.$invalid) {
        //save form
        this.isLoading = true;
        CalendarApiService.updateShipper(this.shipperForm).then(() => {
          this.shipperRef.name = this.shipperForm.name;
          this.shipperRef.code = this.shipperForm.code;
          this.shipperRef.phoneNumber = this.shipperForm.phoneNumber;
          this.isLoading = false;
          this.closeModal();
        });
      }
    },
  },
  validations: {
    shipperForm: {
      name: { required },
      code: { required },
    },
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.editShipper')"
    title-class="text-black font-18"
    hide-footer
    body-class="p-3"
    size="sm"
  >
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    >
    </loading>
    <b-form @submit.prevent="submit">
      <div class="row">
        <b-form-group style="position: relative" class="col-12">
          <label>{{ $t("general.name") }}</label>
          <b-form-input
            v-model="shipperForm.name"
            :class="{
              'is-invalid': shipperFormSubmit && $v.shipperForm.name.$error,
            }"
            :placeholder="$t('general.name')"
          >
          </b-form-input>
          <div
            v-if="shipperFormSubmit && $v.shipperForm.name.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.shipperForm.name.required">{{
              $t("general.requiredValue")
            }}</span>
          </div>
        </b-form-group>

        <b-form-group style="position: relative" class="col-12">
          <label>Code</label>
          <b-form-input
            v-model="shipperForm.code"
            :class="{
              'is-invalid': shipperFormSubmit && $v.shipperForm.code.$error,
            }"
            placeholder="Code"
          >
          </b-form-input>
          <div
            v-if="shipperFormSubmit && $v.shipperForm.code.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.shipperForm.code.required">{{
              $t("general.requiredValue")
            }}</span>
          </div>
        </b-form-group>

        <b-form-group style="position: relative" class="col-12">
          <label>{{ $t("general.phoneNumber") }}</label>
          <b-form-input
            v-model="shipperForm.phoneNumber"
            :placeholder="$t('general.phoneNumber')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="text-right pt-3">
        <b-button variant="light" @click="closeModal">{{
          $t("general.close")
        }}</b-button>
        <b-button type="submit" class="ml-1" variant="success">{{
          $t("general.save")
        }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>